.generated-form {
    padding: 20px; }

.generated-form__header {
    font-size: 26px;
}
.wrapper {
    display: block; }
.wrapper--hidden {
    display: none; }
.wrapper--hidden-path {
    display: none; }
.section--hidden {
    display: none; }

